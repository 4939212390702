import React, { useEffect } from "react";
import { apiClient } from "../Helpers/apiCall";
import { useStore } from "../../store/useStore";
import MainTitle from "../Components/common/MainTitle";
// import Navigation from '../Components/common/Navigation';

// import { useHistory } from 'react-router-dom';

export default function GrowthChat() {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);

	// const { feels } = state;
	const fetchX = (key) => {
		if (!state[key].loading) {
			if (key === "feels") {
				apiCall.GET_FEELS();
				
			}
			if (key === "feels_status") {
				apiCall.GET_FEELS_STATUS();
			}
			// dispatch({ type: `${key.toUpperCase()}_LOADING` });
			// apiCall.GET(`${key}`);
		}
	};

	useEffect(() => {
		// eslint-disable-next-line no-unused-vars
		let isMounted = true;
		fetchX("feels", 'feels_status');

		const handleNPSUpdate = (e) => {
			fetchX("feels");
		}

		window.addEventListener("NPSDialog_closed", handleNPSUpdate);

		return () => {
			window.removeEventListener("NPSDialog_closed", handleNPSUpdate);
			// eslint-disable-next-line no-unused-vars
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={`feedbackContainer all-the-feels`}>
			<div className="feedbackForm">
				<MainTitle>Growth Chat</MainTitle>

				<div className="split-container">
					<div className="content">
						<p>
							Growth Chat is, as the name suggests, a discussion focused on your
							professional growth. It is important that you have reflected on
							and clarified your professional goals as clearly as possible so
							that we can work together to build concrete steps to achieve them.
							Drama Queen supports and encourages its experts in all aspects of
							professional growth.
						</p>
						<h4>
							Here are the instructions for the discussion:
						</h4>
					</div>
					<div className="content">
						<ol>
							<li>
								<p>
									Review your responsibility description and consider whether it
									aligns with your skills. If you find areas in the description
									that do not yet match your expertise, focus on those during
									the discussion.
								</p>
								<a
									className="c-button small light"
									href="https://docs.google.com/presentation/d/1aSUOUoN99OQpz3jbS6mtQO03FRrZM0cSgXiJrbKhP4E/edit#slide=id.g2cd10a2f9ee_0_21"
									target='_blank'
									rel="noreferrer"
								>
									Review responsibility description
								</a>
							</li>
							<li>
								<p>
									Carefully fill out the form available via the provided link;
									it will serve as the basis for the discussion.
								</p>
								<a
									className="c-button small light"
									href="https://docs.google.com/forms/d/e/1FAIpQLSeijO5nwPKey920XWFNy0u3-a_saLGOZFsOClDqq2JQfAhSFw/viewform"
									target='_blank'
									rel="noreferrer"
								>
									Fill out the form
								</a>
							</li>
							<li>
								<p>Wait for a calendar invite to schedule the discussion.</p>
							</li>
						</ol>
					</div>
				</div>
			</div>
		</div>
	);
}
