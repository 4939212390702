import React, { useEffect } from 'react';
import {
	useHistory,
	useLocation,
	Switch,
	Route, Link
} from 'react-router-dom';

import { toast, Flip } from 'react-toastify';

import { useStore } from '../store/useStore';
import { messaging } from '../init-fcm';
import { AdminRoute } from './Routes/AdminRoute';

import 'react-toastify/dist/ReactToastify.css';
import './Styles/App.scss';
import 'tiny-slider/dist/tiny-slider.css';
import Footer from './Components/Footer';
import Splash from './Views/Splash';
import LoginRoute from './Routes/LoginRoute';
import UserRoute from './Routes/UserRoute';
import AppHeader from './Components/AppHeader';
import MainTitle from './Components/common/MainTitle';
import RequestPermissionToggle from './Components/common/RequestPermissionToggle';

function App() {
	const { state } = useStore();
	const { user } = state;

	const { pathname } = useLocation();
	var history = useHistory();


	if (messaging) {
		messaging.onMessage(function (payload) {
			// Messages received. Either because the
			// app is running in the foreground, or
			// because the notification was clicked.
			// `payload` will contain your data.

			const notification = payload.notification;
			// console.log('onMessage', payload);
			toast(`${notification.title}`, {
				position: toast.POSITION.TOP_CENTER,
				transition: Flip,
				onClick: () => {
					// console.log(window, notification);
					history.push(payload.data.click_action);
				},
			});
		});
	}

	useEffect(() => {
		window.scrollTo(0, 0);

		const currentBackgroundColor = getComputedStyle(
			document.querySelector('.app-container')
		).getPropertyValue('--background');

		document
			.querySelector('[name="theme-color"]')
			.setAttribute('content', currentBackgroundColor);
		// location.pathname.replaceAll('/', ' ');
	}, [pathname]);

	const loggedIn = user.loggedIn;
	const loading = user.loading;
	const location = useLocation();	

	const needsOnboarding = user && user.data && user.data.needs_onboarding;

	return (
		<>
			<div
				className={`app-container ${location.pathname.replaceAll("/", " ")} ${
					needsOnboarding ? "onboarding" : ""
				}`}
			>
				{loading && <Splash />}
				<Switch>
					<Route path="/intro" exact>
						<IntroRoute user={user} />
					</Route>
					{!loggedIn && <LoginRoute />}
					{loggedIn && (
						<Route
							path="/admin"
							render={({ match: { url } }) => {
								return <AdminRoute url={url} />;
							}}
						/>
					)}
					{loggedIn && <UserRoute />}					
					{/* <Route path="*" render={() => <Redirect to="/" />} /> */}					
				</Switch>
			</div>
			<Footer />
		</>
	);
}

const IntroRoute = ({ user }) => {

	const isInStandaloneMode = () =>
		window.matchMedia("(display-mode: standalone)").matches ||
		window.matchMedia("(display-mode: fullscreen)").matches ||
		window.matchMedia("(display-mode: minimal-ui)").matches ||
		window.matchMedia("(display-mode: fullscreen)").matches ||
		window.navigator.standalone ||
		document.referrer.includes("android-app://");

	if (isInStandaloneMode()) {
		console.log("webapp is installed");
	}

	const androidVideo =
		"https://player.vimeo.com/progressive_redirect/playback/1040308800/rendition/1080p/file.mp4?loc=external&signature=e246e7542070a337d0badac15b937ec6e282254a605ce94d45ff0c7dddcd8c95";
	const iosVideo =
		"https://player.vimeo.com/progressive_redirect/playback/1040308800/rendition/1080p/file.mp4?loc=external&signature=e246e7542070a337d0badac15b937ec6e282254a605ce94d45ff0c7dddcd8c95";
	
	const video = !navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? iosVideo : androidVideo;
	const notificationsInUse = typeof Notification !== "undefined" && Notification.permission === "granted";
	
	return (
		<div className="container intro-container">
			<AppHeader />
			<div className="page login-page">
				<MainTitle className={"center"}>
					Welcome to <span>the Royal Backstage</span>
				</MainTitle>
				<div className="intro-btn-wrapper">
					<div className="video-wrapper">
						<video controls playsInline autoPlay loop muted src={video}></video>
					</div>
					<div>
						<h4>
							<strong>Here's how to get started:</strong>
						</h4>
						<ol>
							<li className={isInStandaloneMode() ? "done" : ""}>
								{isInStandaloneMode() && (
									<span role="img" aria-label="check">
										✅
									</span>
								)}
								<strong>Save this website to your home screen.</strong>
							</li>
							<li className={user.loggedIn ? "done" : ""}>
								{user.loggedIn && (
									<span role="img" aria-label="check">
										✅
									</span>
								)}
								<strong>
									Open the saved app and login with your DQCOMMS account
								</strong>
							</li>
							<li className={notificationsInUse ? "done" : ""}>
								{notificationsInUse && (
									<span role="img" aria-label="check">
										✅
									</span>
								)}
								<strong>
									Enable notifications to keep up to date with the latest news
								</strong>
								{user.loggedIn && (
									<>
										<div>
											<RequestPermissionToggle />
										</div>

										{/* <p className="debug-info">
											<strong>Notifications supported?</strong>
											<br />
											<span>
												{typeof Notification !== "undefined" &&
												"Notification" in window
													? "Yes"
													: "No"}
											</span>
										</p>
										<p className="debug-info">
											<strong>Notifications in use?</strong>
											<br />
											<span>{notificationsInUse ? "granted" : "-"}</span>
										</p> */}
									</>
								)}
							</li>
							<li>
								<strong>That's it!</strong>
								<br />
								<br />

								{user.loggedIn && notificationsInUse && (
									<Link to="/" className="c-button">
										Go to the Royal Backstage
									</Link>
								)}
							</li>
						</ol>
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;