import { React, useState, useEffect } from 'react';
import { useStore } from '../../store/useStore';
import { apiClient } from '../Helpers/apiCall';

import { useHistory, Route } from 'react-router-dom';

import { formatISO9075 } from 'date-fns';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
// import AppHeader from '../Components/AppHeader';
// import PageHeader from '../Components/PageHeader';
import Feed from '../Views/Feed';
import Manual from '../Views/Manual';
import Contacts from '../Views/Contacts';
import FeedbackForm from '../Views/FeedbackForm';
import Profile from '../Views/Profile';
import AppHeader from '../Components/AppHeader';
import Search from '../Views/Search';
import { Frontpage } from '../Views/Frontpage';
import Weekly from '../Views/Weekly';
import Draamattupiiri from '../Views/Draamattupiiri';
import Pods from '../Views/Pods';
import OnBoarding from '../Views/OnBoarding';
import { NPSModal } from '../Components/NPS';
import Feels from '../Views/Feels';
import GrowthChat from '../Views/GrowthChat';

const UserRoute = () => {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);
	const { user } = state;
	const { data: currentUser } = user;

	const [searchFilter, setSearchFilter] = useState('');

	// eslint-disable-next-line no-unused-vars
	const [needsOnboarding, setNeedsOnboarding] = useState(
		user.data.needs_onboarding
	);

	var history = useHistory();

	const fetchX = (key) => {
		if (!state[key].loading) {

			if (key === "feels") {
				apiCall.GET_FEELS_STATUS();
				return false;
			}

			dispatch({ type: `${key.toUpperCase()}_LOADING` });
			apiCall.GET(`${key}`);
		}
	};

	const requiredData = ['users', 'feed', 'groups', 'ack', 'pages', 'weekly', 'pods', 'skills', 'feels'];

	useEffect(() => {
		// eslint-disable-next-line no-unused-vars
		let isMounted = true;
		requiredData.forEach((item) => {
			fetchX(item);
		});
		return () => {
			// eslint-disable-next-line no-unused-vars
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const endOnboarding = () => {
		apiCall
			.POSTRAW(`users/${user.data.id}`, 'user', {
				last_onboarding: formatISO9075(new Date()),
			})
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type: 'USER_REFRESH', payload: response });
				}
			});

		setTimeout(() => {
			setNeedsOnboarding(false);
		}, 2000);
			
	};

	const reEnableOnboarding = () => {
		apiCall
			.POSTRAW(`users/${user.data.id}`, 'users', {
				last_onboarding: null,
			})
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type: 'USER_REFRESH', payload: response });
				}
				history.push('/');
			});
		setNeedsOnboarding(true);
	};


	return (
		<SwitchTransition mode={"out-in"}>
			<CSSTransition
				key={needsOnboarding}
				addEndListener={(node, done) => {
					node.addEventListener("transitionend", done, false);
				}}
				classNames="fade"
			>
				<>
					{needsOnboarding && (
						<OnBoarding endOnboarding={endOnboarding} user={currentUser} />
					)}
					{!needsOnboarding && (
						<div>
							<AppHeader
								searchFilter={searchFilter}
								setSearchFilter={setSearchFilter}
							/>
							<div className="container">
								<Route
									path="/"
									exact
									render={() => {
										return <Frontpage />;
									}}
								/>
								<Route
									path="/search"
									exact
									render={() => {
										return (
											<>
												<Search
													searchFilter={searchFilter}
													setSearchFilter={setSearchFilter}
													isOpen={true}
												/>
											</>
										);
									}}
								/>
								<Route
									path="/feed/:id"
									render={({
										match: {
											params: { id },
										},
										match,
									}) => {
										return (
											<>
												<Feed feedItemId={id}></Feed>
											</>
										);
									}}
								/>
								<Route
									path="/feed"
									exact
									render={() => {
										return (
											<>
												<Feed />
											</>
										);
									}}
								/>
								<Route
									path="/manual/:id?"
									render={({
										match: {
											params: { id },
										},
										match,
									}) => {
										return <Manual pageId={id}></Manual>;
									}}
								/>
								<Route
									path="/weekly/:id?"
									render={({
										match: {
											params: { id },
										},
										match,
									}) => {
										return <Weekly pageId={id}></Weekly>;
									}}
								/>
								{/* <Route
									path="/contacts/:id?"
									render={({
										match: {
											params: { id },
										},
									}) => {
										return <Contacts contactId={id}></Contacts>;
									}}
								/> */}
								{/* <Route path='/contacts' element={<Contacts />}>
									<Route
										path=':contactId'
										element={<Contacts />}
									/>
								</Route> */}
								<Route
									path="/contacts/:id?"
									render={({
										match: {
											params: { id },
										},
									}) => {
										return <Contacts contactId={id}></Contacts>;
									}}
								/>
								<Route
									path="/podlings/:id?"
									render={({
										match: {
											params: { id },
										},
									}) => {
										return <Pods podId={id}></Pods>;
									}}
								/>
								<Route path="/feedback">
									<FeedbackForm />
								</Route>
								<Route path="/feels">
									<Feels />
								</Route>
								<Route path="/growth-chat">
									<GrowthChat />
								</Route>
								<Route path="/profile">
									<Profile reEnableOnboarding={reEnableOnboarding} />
								</Route>
								<Route path="/draamattupiiri">
									<Draamattupiiri />
								</Route>
								{/* <Route path="*" render={() => <Redirect to="/" />} /> */}
							</div>
							
							{state.feels?.feelsStatus !== null && (
								<NPSModal
									useOverlay
									isEnabledFn={() => {

										const date = new Date();
										const compDate = state.user.feelsIgnored ? new Date(state.user.feelsIgnored) : 0;
										if (compDate > date) {
											// console.log('Feels ignored until', state.user.feelsIgnored);
											return false;
										}

										if (!!state?.feels?.feelsStatus) {
											// console.log('Feels already answered', state.feels.feelsStatus);
											return false;
										}

										return true
									}}
									onDismiss={() => {

										const date = new Date();
										date.setDate(date.getDate() + ((7 - date.getDay()) % 7) + 1);
										date.setHours(0, 0, 0, 0);

										dispatch({
											type: "FEELS_IGNORED",
											payload: { data: { data: date.toUTCString() } },
										});
									}}
									user={state.user}
									response={state?.feels?.feelsStatus ?? false}

								/>)}
						</div>
					)}
				</>
			</CSSTransition>
		</SwitchTransition>
	);
};

export default UserRoute;
