import React, { useEffect } from "react";
import { apiClient } from "../Helpers/apiCall";
import { useStore } from "../../store/useStore";
import MainTitle from "../Components/common/MainTitle";
import { NPSTrigger } from "../Components/NPS";
import {
	EmojiAnswer1,
	EmojiAnswer2,
	EmojiAnswer3,
	EmojiAnswer4,
	EmojiAnswer5,
} from "../Components/common/NPSicons";
import Loader from '../Components/common/Loader';
// import Navigation from '../Components/common/Navigation';

// import { useHistory } from 'react-router-dom';

export default function Feels() {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);

	// const { feels } = state;
	const fetchX = (key) => {
		if (!state[key].loading) {
			if (key === "feels") {
				apiCall.GET_FEELS();
				
			}
			if (key === "feels_status") {
				apiCall.GET_FEELS_STATUS();
			}
			// dispatch({ type: `${key.toUpperCase()}_LOADING` });
			// apiCall.GET(`${key}`);
		}
	};

	useEffect(() => {
		// eslint-disable-next-line no-unused-vars
		let isMounted = true;
		fetchX("feels", 'feels_status');

		const handleNPSUpdate = (e) => {
			fetchX("feels");
		}

		window.addEventListener("NPSDialog_closed", handleNPSUpdate);

		return () => {
			window.removeEventListener("NPSDialog_closed", handleNPSUpdate);
			// eslint-disable-next-line no-unused-vars
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={`feedbackContainer all-the-feels`}>
			<div className="feedbackForm">
				<MainTitle>All the feels</MainTitle>

				<div className="split-container">
					<div className="content">
						<h3>Mood tracker</h3>
						<CurrentMood />
						<p>
							The weekly mood tracker shows how we’re doing at Drama Queen.<br/><br/>
							Using the mood tracker is easy: just click (weekly) on the face
							that best represents your current mood. This is done anonymously.
							Follow the weekly updates on this page to see how things are
							going. If you’d like to share your feelings with HR, please fill
							out the open field and wait for HR to contact you.
						</p>
						<p>
							Do you want to share your feelings with HR? Great, because sharing
							is caring. The deal with sharing feelings is this: all feelings
							must be shared under your own name, so HR can reach out to you
							regarding your matter.
						</p>
						<NPSTrigger>
							<button className="c-button rosa">Share your feelings</button>
						</NPSTrigger>
						<Separator />
						{/* <FeelingStats />
						<Separator /> */}
					</div>
					<div className="content">
						<WeeklyFeels2 />
					</div>
				</div>
			</div>
		</div>
	);
}

const Separator = () => { 
	return (<div className="separator" style={{ margin: '4em', color:"transparent", borderBottom:'1px solid rgba(255,255,255,0.3)' }}>---</div>);
}
/* 
function getStartAndEndOfWeek() {
	const today = new Date();

	// Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
	const dayOfWeek = today.getDay();

	// Calculate the difference from Monday
	const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // If today is Sunday, set to 6

	// Set start date to Monday of the current week
	const startOfWeek = new Date(today);
	startOfWeek.setDate(today.getDate() - diffToMonday);

	// Set end date to Sunday of the current week
	const endOfWeek = new Date(startOfWeek);
	endOfWeek.setDate(startOfWeek.getDate() + 6);

	// Return formatted start and end dates
	return {
		// startOfWeek: startOfWeek.toISOString().split("T")[0], // yyyy-mm-dd format
		// endOfWeek: endOfWeek.toISOString().split("T")[0], // yyyy-mm-dd format
		startOfWeek: startOfWeek,
		endOfWeek: endOfWeek
	};
} */

function WeeklyFeels2() {
	const { state } = useStore();
	const { feels } = state;

	let data = React.useMemo(() => feels?.items ?? [], [feels]);
	
	data = [...data].reverse();
	
	return (
		<>
			<h3>Weekly feels</h3>
			{(!feels || feels.loading) && <Loader />}
			{!feels.loading &&
				data
					.map((week) => <CurrentMood week={week} key={week.week} />)}
		</>
	);
}


export const CurrentMood = ({ week = false }) => {
	const { state } = useStore();
	const { feels } = state;

	let data = React.useMemo(() => feels?.items ?? [], [feels]);

	const realweek = week ? week :  data[data.length - 1];
	const theAverage = realweek ? realweek.avg : null;

	const noAnswers = !theAverage;
	const showAnswerCount = week || !noAnswers;

	const theAverageDisplay = theAverage
		? theAverage.toFixed(2)
		: !showAnswerCount
		? "No feels yet, be the first!"
		: "--";

	// pick correct emoji to fit the average mood
	const Emoji = React.useMemo(() => {
		if (theAverage === null) {
			return <EmojiAnswer3 />;
		}

		if (theAverage <= 1.5) {
			return <EmojiAnswer1 />;
		} else if (theAverage <= 2.5) {
			return <EmojiAnswer2 />;
		} else if (theAverage <= 3.5) {
			return <EmojiAnswer3 />;
		} else if (theAverage <= 4.5) {
			return <EmojiAnswer4 />;
		} else {
			return <EmojiAnswer5 />;
		}
	}, [theAverage]);

	if (!feels || feels.loading) {
		return (
			<div className={`current-mood ${!!week ? "archive" : ""}`}>
				<div className="current-mood__score">
					<EmojiAnswer3 />
					<Loader />
				</div>
				<div className="current-mood__description">
					<p>-- answers</p>
				</div>
			</div>
		);
	}

	const formatWeekNumber = (week) => {
		if (week) {
			week = week.toString();
			const [year, weekNumber] = [week.slice(0, 4), week.slice(4)];

			return `W${weekNumber}/${year}`;
		}
		return "";
	}


	return (
		<NPSTrigger>
			<div
				className={`current-mood ${!!week ? "archive" : "this-week"} ${
					noAnswers ? "no-answers" : ""
				}`}
			>
				<div className="current-mood__score">
					{Emoji}
					<span>{theAverageDisplay}</span>
				</div>
				<div className="current-mood__description">
					{showAnswerCount && (
						<p>
							{realweek.count} responses
							{week && <strong> - {formatWeekNumber(realweek.week)}</strong>}
						</p>
					)}
				</div>
			</div>
		</NPSTrigger>
	);
};

/*
function WeeklyFeels() {
	const { state } = useStore();
	const { feels } = state;

	let data = React.useMemo(() => feels?.items ?? [], [feels]);



	const items = groupByWeek(data);
	
	const weeklyFeelsArray = Object.keys(items)
		.map((week) => ({
			week,
			items: items[week],
			startOfWeek: new Date(items[week][0].created_at),
			endOfWeek: new Date(items[week][items[week].length - 1].created_at),
		}))
		.reverse();

	return (
		<>
			<h3>Weekly feels</h3>
			{(!feels || feels.loading) && <Loader />}
			{ !feels.loading && weeklyFeelsArray.map((week) => <CurrentMood week={week} key={week.week} />)}
		</>
	);
}

function getWeekNumber(date) {
	const tempDate = new Date(
		Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
	);
	const dayNum = tempDate.getUTCDay() || 7; // Make sure Sunday is treated as the last day of the week
	tempDate.setUTCDate(tempDate.getUTCDate() + 4 - dayNum);
	const yearStart = new Date(Date.UTC(tempDate.getUTCFullYear(), 0, 1));
	const weekNo = Math.ceil(((tempDate - yearStart) / 86400000 + 1) / 7);
	return [tempDate.getUTCFullYear(), weekNo]; // Returns year and week number
}

function groupByWeek(items) {
	return items.reduce((groups, item) => {
		const date = new Date(item.created_at);
		const [year, week] = getWeekNumber(date);
		const key = `${year}-W${week}`;

		if (!groups[key]) {
			groups[key] = [];
		}

		groups[key].push(item);
		return groups;
	}, {});
}
*/

/*
export const CurrentMood = ({ week = false }) => {

	const { state } = useStore();
	const { feels } = state;

	let data = React.useMemo(() => feels?.items ?? [], [feels]);

	const realweek = week ? week : getStartAndEndOfWeek();
	const { startOfWeek: start, endOfWeek: end } = realweek;
	
	if (start && end) {
		data = data.filter((item) => {
			const date = new Date(item.created_at);
			return date >= start && date <= end;
		});
	}

	// Build the sum and average of scores
	const theSum = data.reduce((acc, s) => acc + s.score, 0);
	const theAverage = data.length ? theSum / data.length : null;


	const noAnswers = !theAverage;
	const showAnswerCount = week || !noAnswers;


	const theAverageDisplay = theAverage ? theAverage.toFixed(2) : !showAnswerCount ? "No feels yet, be the first!" : "--";

	// pick correct emoji to fit the average mood
	const Emoji = React.useMemo(() => {
		if (theAverage === null) {
			return <EmojiAnswer3 />;
		}

		if (theAverage <= 1.5) {
			return <EmojiAnswer1 />;
		} else if (theAverage <= 2.5) {
			return <EmojiAnswer2 />;
		} else if (theAverage <= 3.5) {
			return <EmojiAnswer3 />;
		} else if (theAverage <= 4.5) {
			return <EmojiAnswer4 />;
		} else {
			return <EmojiAnswer5 />;
		}
	}, [theAverage]);

	if (!feels || feels.loading) {
		return (
				<div className={`current-mood ${!!week ? "archive" : ""}`}>
					<div className="current-mood__score">
						<EmojiAnswer3 />
						<Loader />
					</div>
					<div className="current-mood__description">
						<p>
							-- answers							
						</p>
					</div>
				</div>
		);
	}

	return (
		<NPSTrigger>
			<div
				className={`current-mood ${!!week ? "archive" : "this-week"} ${
					noAnswers ? "no-answers" : ""
				}`}
			>
				<div className="current-mood__score">
					{Emoji}
					<span>{theAverageDisplay}</span>
				</div>
				<div className="current-mood__description">
					{showAnswerCount && (
						<p>
							{data.length} responses
							{week && <strong> / {week.week}</strong>}
						</p>
					)}
				</div>
			</div>
		</NPSTrigger>
	);
}
*/

/*
function FeelingStats({ start = false, end = false }) {
	

	const { state } = useStore();
	const { feels } = state;

	let data = React.useMemo(() => feels?.items ?? [], [feels]);

	if (start && end) {
		data = data.filter((item) => {
			const date = new Date(item.created_at);
			return date >= start && date <= end;
		});
	}

	// Build the sum and average of scores	
	const theSum = data.reduce((acc, s) => acc + s.score, 0);
	const theAverage = theSum / data.length;

	const canvasRef = React.useRef(null);
	const scores = React.useMemo(() => ({
		scoreOne: data.filter((s) => s.score === 1),
		scoreTwo: data.filter((s) => s.score === 2),
		scoreThree: data.filter((s) => s.score === 3),
		scoreFour: data.filter((s) => s.score === 4),
		scoreFive: data.filter((s) => s.score === 5),
	}), [data]);
	
	const scorePercentages = React.useMemo(() => ({
		scoreOne: ((scores.scoreOne.length / data.length) * 100).toFixed(0),
		scoreTwo: ((scores.scoreTwo.length / data.length) * 100).toFixed(0),
		scoreThree: ((scores.scoreThree.length / data.length) * 100).toFixed(0),
		scoreFour: ((scores.scoreFour.length / data.length) * 100).toFixed(0),
		scoreFive: ((scores.scoreFive.length / data.length) * 100).toFixed(0),
	}), [data, scores]);

	useEffect(() => {
		// Build the pie chart
		let pieResults = [
			{ total: scores.scoreFive.length, shade: "#28284f" },
			{ total: scores.scoreFour.length, shade: "#9293a7" },
			{ total: scores.scoreThree.length, shade: "#d4d4dc" },
			{ total: scores.scoreTwo.length, shade: "#fcabad" },
			{ total: scores.scoreOne.length, shade: "#f7575b" },
		];

		let totalNumberOfScoreOptions = pieResults.reduce(
			(sum, { total }) => sum + total,
			0
		);

		// start current angle at 90 in radians
		let currentAngle = -0.5 * Math.PI;

		const canvas = canvasRef.current;
		const ctx = canvas.getContext("2d");
		
		let multiplier = 2;
		canvas.width = 250 * multiplier;
		canvas.height = 250 * multiplier;

		for (let scoreValue of pieResults) {
			// Calculating the angle the slice (portion) will take in the chart
			let portionAngle =
				(scoreValue.total / totalNumberOfScoreOptions) * 2 * Math.PI;

			// Drawing an arc and a line to the center to differentiate the slice from the rest			
			ctx.beginPath();
			ctx.arc(125 * multiplier, 125 * multiplier, 125 * multiplier, currentAngle, currentAngle + portionAngle);
			currentAngle += portionAngle;
			ctx.lineTo(125 * multiplier, 125 * multiplier);
			ctx.fillStyle = scoreValue.shade;
			ctx.fill();
		}
	}, [canvasRef, scores]);


	if (!feels || feels.loading) {
		return <Loader />;
	}

	return (
		<div className="results-overview">
			<div className="results-wrapper">
				<div className="results-overall">
					<h3>All the feels</h3>
				</div>

				<div className="results-overall">
					<ul>
						<li>
							<span>{data.length}</span> Responses
						</li>
						<li>
							<span>{theAverage.toFixed(2)}</span> Average rating
						</li>
					</ul>
				</div>

				<div className="results-chart" style={{ margin: "2em" }}>
					<canvas
						id="pie-chart"
						width="250"
						height="250"
						ref={canvasRef}
						style={{ width: "250px", height: "250px" }}
					></canvas>
				</div>

				<ul id="graph-numbers">
					<li className="graph-number" id="graph-number-one">
						<span>{scorePercentages.scoreOne} %</span> Drained
					</li>
					<li className="graph-number" id="graph-number-two">
						<span>{scorePercentages.scoreTwo} %</span> Down
					</li>
					<li className="graph-number" id="graph-number-thee">
						<span>{scorePercentages.scoreThree} %</span> Okay
					</li>
					<li className="graph-number" id="graph-number-four">
						<span>{scorePercentages.scoreFour} %</span> Good
					</li>
					<li className="graph-number" id="graph-number-five">
						<span>{scorePercentages.scoreFive} %</span> Awesome
					</li>
				</ul>
			</div>
		</div>
	);
}
*/