import React from "react";
import { Route, Redirect, Switch } from 'react-router-dom';
import AppHeader from '../Components/AppHeader';
// import LoginForm from '../Views/LoginForm';
import LoginPage from '../Views/LoginPage';
import MainTitle from '../Components/common/MainTitle';

const LoginRoute = () => {
	return (
		<Switch>			
			<Route path='/' exact>
				<div className='container login-container'>
					<AppHeader />
					<LoginPage />
				</div>
			</Route>
			<Route path="*" render={() => <Redirect to="/" />} />
		</Switch>
	);
};

export default LoginRoute;