import React from 'react';
import { Link } from 'react-router-dom';

import MainTitle from '../Components/common/MainTitle';
import IGFeed from '../Components/IGFeed';
import Ticker from '../Components/Ticker';
import Feed from './Feed';


import IconRoster from '../../images/icon_roster.svg';
import IconApplauds from '../../images/icon_applauds.svg';
import IconToolkit from '../../images/icon_toolkit.svg';
import IconWeekly from '../../images/icon_weekly.svg';
import IconFeedback from '../../images/icon_feedback.svg';
import IconPods from '../../images/icon_podlings.svg';
import IconSmiley from '../../images/icon_smiley.svg';
import IconGrowthChat from '../../images/icon_growthchat.svg';
// import { isAdmin } from '../Helpers/common';
// import { useStore } from '../../store/useStore';


// const SnazzyMenu = () => {

// 	const links = [
// 		{
// 			img: IconToolkit,
// 			label: "Toolkit",
// 			link: "/manual",
// 		},
// 		{
// 			img: IconRoster,
// 			label: "Roster",
// 			link: "/contacts",
// 		},
// 		{
// 			img: IconApplauds,
// 			label: "Applauds",
// 			link: "/draamattupiiri",
// 		},
// 		{
// 			img: IconFeedback,
// 			label: "Feedback",
// 			link: "/feedback",
// 		},
// 		{
// 			img: IconWeekly,
// 			label: "Weekly",
// 			link: "/weekly",
// 		},
// 		{
// 			img: IconPods,
// 			label: "Podlings",
// 			link: "/podlings",
// 		},
// 	];

// 	return (
// 		<ul className='c-snazzymenu'>
// 			{links.map((i) => {
// 				return (
// 					<li key={i.link}>
// 						<Link
// 							to={{
// 								pathname: i.link,
// 							}}
// 						>
// 							{[...Array(4)].map((x, b) => (
// 								<img
// 									key={`img-${b}`}
// 									src={i.img}
// 									className={`level-${b}`}
// 									alt={i.label}
// 								/>
// 							))}
// 							<span>{i.label}</span>
// 						</Link>
// 					</li>
// 				);
// 			})}
// 		</ul>
// 	);
// }
const IconMenu = () => {

	const links = [
		{
			img: IconToolkit,
			label: "Toolkit",
			link: "/manual",
		},
		{
			img: IconRoster,
			label: "Roster",
			link: "/contacts",
		},
		{
			img: IconApplauds,
			label: "Applauds",
			link: "/draamattupiiri",
		},
		{
			img: IconFeedback,
			label: "Feedback",
			link: "/feedback",
		},
		{
			img: IconWeekly,
			label: "Weekly",
			link: "/weekly",
		},
		{
			img: IconPods,
			label: "Podlings",
			link: "/podlings",
		},
		{
			img: IconSmiley,
			label: "Feels",
			link: "/feels",
		},
		{
			img: IconGrowthChat,
			label: "Growth Chat",
			link: "/growth-chat",
		},
	];

	// const { state } = useStore();
	// const { user } = state;

	// if (user?.data && isAdmin(user.data)) {
	// 	links.push({
	// 		img: IconGrowthChat,
	// 		label: "Growth Chat",
	// 		link: "/growth-chat",
	// 	});
	// }

	return (
		<ul className="c-iconmenu">
			{links.map((i) => {
				return (
					<li key={i.link}>
						<Link
							to={{
								pathname: i.link,
							}}
						>
							<img key={`img`} src={i.img} alt={i.label} />
							<span>{i.label}</span>
						</Link>
					</li>
				);
			})}

				{/* <NPSTrigger>
					<li key="npstrigger">
						<button>
							<img key={`img`} src={IconSmiley} alt={"Feels"} />
							<span>{"Feels"}</span>
						</button>
					</li>
				</NPSTrigger> */}
		</ul>
	);
}

export const Frontpage = () => {
	return (
		<div className="c-frontpage">
			<MainTitle className={'center'}>Royal Backstage</MainTitle>
			<Ticker />

			<div className="widelayout">
				{/* <SnazzyMenu /> */}
				<IconMenu />

				<Feed />
				<IGFeed />				
			</div>
		</div>
	);
};
